.ezy__featured28 {
	/* Bootstrap variables */
	--bs-body-color: #0f111a;
	--bs-body-bg: #fff;

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-shadow: 0px 4px 44px rgba(159, 190, 218, 0.37);
	--ezy-shape-color: #fef3fe;

	background-color: var(--bs-body-bg);
	overflow: hidden;
	padding: 30px 0;
	position: relative;
}

@media (min-width: 768px) {
	.ezy__featured28 {
		padding: 60px 0;
	}
}

.gray .ezy__featured28,
.ezy__featured28.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);
}

/* Dark Gray Block Style */
.dark-gray .ezy__featured28,
.ezy__featured28.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);

	/* Easy Frontend variables */
	--ezy-shadow: 0px 4px 44px rgba(0, 0, 0, 0.37);
}

/* Dark Block Style */
.dark .ezy__featured28,
.ezy__featured28.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);

	/* Easy Frontend variables */
	--ezy-shadow: 0px 4px 44px rgba(0, 0, 0, 0.37);
}

.ezy__featured28-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

@media (min-width: 768px) {
	.ezy__featured28-heading {
		font-size: 45px;
		line-height: 55px;
	}
}

.ezy__featured28-sub-heading {
	color: var(--bs-body-color);
	font-size: 22px;
	line-height: 1.5;
	opacity: 0.8;
}

.ezy__featured28-btn {
	padding: 12px 25px;
	box-shadow: var(--ezy-shadow);
	background-color: var(--ezy-theme-color);
	border-color: var(--ezy-theme-color);
}

.ezy__featured28-btn:hover {
	background-color: rgba(var(--ezy-theme-color-rgb), 0.9);
	border-color: rgba(var(--ezy-theme-color-rgb), 0.9);
}

.ezy__featured28-bg-holder {
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 350px;
	width: 100%;
}

@media (min-width: 991px) {
	.ezy__featured28-bg-holder {
		float: right;
		height: 100%;
	}
}

.ezy__featured28-shape {
	background-color: var(--ezy-shape-color);
	border-radius: 40px 0 0 40px;
	position: absolute;
	left: 55%;
	bottom: 0;
	top: 0;
	width: 50vw;}
