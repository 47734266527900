.ezy__about6 {
	/* Bootstrap variables */
	--bs-body-color: #1e2329;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;

	background-color: var(--bs-body-bg);
	color: var(--bs-body-color);
	overflow: hidden;
	padding: 60px 0;
}

@media (min-width: 768px) {
	.ezy__about6 {
		padding: 100px 0;
	}
}

/* Gray Block Style */
.gray .ezy__about6,
.ezy__about6.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);
}

/* Dark Gray Block Style */
.dark-gray .ezy__about6,
.ezy__about6.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
}

/* Dark Block Style */
.dark .ezy__about6,
.ezy__about6.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);
}

.ezy__about6-heading {
	font-size: 35px;
	line-height: 45px;
}
@media (min-width: 768px) {
	.ezy__about6-heading {
		font-size: 50px;
		line-height: 70px;
	}
}
.ezy__about6-sub-heading {
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;
}

.ezy__about6-banner {
	min-height: 300px;
	background-size: cover;
	background-position: center;
}

.ezy__about6-content p {
	font-size: 16px;
	line-height: 1.7;
	opacity: 0.7;
}

.ezy__about6 img {
	border-radius: 15px;
}
