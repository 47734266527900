
.container {
    gap: 10px;
    h1 {
        font-size: 70px;
        color: white;
    }
    p {
        color: white;
    }
    .bg-danger {
        padding: 10px;
        width: 150px;
        border: none;
        font-size: 18px;
        border-radius: 5px;
    }
}
.margin{
    margin-top: 250px;
}
.zoro{
    margin-top: 150px;
}

.next-section {
    position: relative;
}
.about-image{
    height: 500px;
    width: 500px;
    img{
        object-fit: cover;
        border-radius: 50%;
    }
}
.points{
    // background-color: #2e2d2d;
    .sub-points{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .items{
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          flex-direction: column;
          p{
            margin: unset;
          }
        }
    }
   
}

@media(max-width:800px){
    .points{
        .sub-points{
            grid-template-columns:  1fr 1fr 1fr;
            row-gap: 20px;
            
            .items{
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              flex-direction: column;
              p{
                margin: unset;
              }
            }
        }
       
    }
}
@media(max-width:800px){
    .container {
        h1 {
            font-size: 55px;
            color: white;
        }
        
    }
}
@media(max-width:500px){
    .points{
        .sub-points{
            grid-template-columns: 1fr 1fr;
            row-gap: 10px;
            
            .items{
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              flex-direction: column;
              p{
                margin: unset;
              }
            }
        }
       
    }
}
@media(max-width:450px){
    .container {
        h1 {
            font-size: 35px;
            color: white;
        }
        
    }
    .points{
        .sub-points{
            grid-template-columns: 1fr;
            row-gap: 30px;
            
            .items{
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              flex-direction: column;
              p{
                margin: unset;
              }
            }
        }
       
    }
}


