.ezy__about9 {
	/* Bootstrap variables */
	--bs-body-color: #1e2329;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;

	background-color: var(--bs-body-bg);
	color: var(--bs-body-color);
	overflow: hidden;
	padding: 60px 0;
}

@media (min-width: 768px) {
	.ezy__about9 {
		padding: 100px 0;
	}
}

/* Gray Block Style */
.gray .ezy__about9,
.ezy__about9.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);
}

/* Dark Gray Block Style */
.dark-gray .ezy__about9,
.ezy__about9.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
}

/* Dark Block Style */
.dark .ezy__about9,
.ezy__about9.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);
}

.ezy__about9-heading {
	font-size: 32px;
	line-height: 1;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
.ezy__about9-sub-heading {
	opacity: 0.7;
}

.ezy__about9 img {
	border-radius: 15px;
}

.ezy__about9-divider {
	color: var(--ezy-theme-color);
	height: 2px !important;
	border-radius: 3px;
	width: 50px;
	opacity: 1;
}

.ezy__about9 .text-primary {
	color: var(--ezy-theme-color) !important;
}
.ezy__about9-features hr {
	width: 90%;
}
.ezy__about9-btn {
	background-color: var(--bs-body-color) !important;
	color: var(--bs-body-bg) !important;
	padding: 8px 25px !important;
	transition: 0.5s !important;
}
.ezy__about9-btn:hover {
	color: var(--bs-body-bg);
	opacity: 0.9;
}
