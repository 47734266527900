.header {
  width: 100%;
  position: fixed;
  z-index: 20000;
  background-color: transparent;
  background-color: white;
  padding: unset !important;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  // &.scrolled {
  //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  // }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 170px;
      height: 70px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    ul {
      display: flex;
      align-items: center;
      gap: 50px;
      margin-bottom: unset !important;
      margin-top: 15px !important;
      transition: color 0.3s ease;

      a {
        text-decoration: none;

        li {
          list-style-type: none;
          font-size: 20px;
          color: rgb(10, 10, 10); // Default color before scroll
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;

          .icon-b {
            display: none;
          }

          // Active link underline
          &.active-link {
            border-bottom: 2px solid rgb(111, 174, 238); // Customize color
          }
        }
      }
    }

    .ham {
      display: none;
      cursor: pointer;
      position: fixed;
      top: 50px;
      right: 20px;
      z-index: 4000000;
      transition: color 0.3s ease;
    }

    @media (max-width: 800px) {
      .ham {
        display: block;
        color: white; // Default color before scroll
      }

      ul {
        position: fixed;
        flex-direction: column;
        top: -120%;
        background-color: rgb(5, 5, 5);
        opacity: 0.9;
        height: 100vh !important;
        width: 100%;
        align-items: flex-start !important;
        justify-content: center;
        gap: 80px;
        transition: top 0.3s ease, background-color 0.3s ease;
        z-index: 9999;
        left: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

        a {
          li {
            color: rgb(252, 250, 250); // Menu item color for mobile view

            .icon-b {
              display: block;
            }
          }
        }
      }

      ul.open {
        top: -20px;
      }

      .header {
        position: relative;
        z-index: 10000;
      }
    }
  }

  // When scrolled, update the link colors and hamburger menu color
  &.scrolled {
    .container {
      ul a li {
        color: black; // Change text color on scroll
      }

      .ham {
        color: black; // Change hamburger color on scroll
      }
    }
  }
}
