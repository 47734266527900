.round{
   border-radius: 50%;
   width: 200px !important;
   height: 200px !important; 
   // background-color: red;
   object-fit: contain;
}
@media(max-width:400px){
    .round{
        width: 200px !important;
        height: 200px !important; 
     }   
}